import request from "@/router/axios";

export const getMenusApi = params => {
    return request({
        url: "/api/system/menu/list",
        method: "get",
        params
    });
};

export const getMenuPageApi = params => {
    return request({
        url: "/api/system/menu/page",
        method: "get",
        params
    });
};

export const addMenuApi = data => {
    return request({
        url: "/api/system/menu/insert",
        method: "post",
        data
    });
};

export const updateMenuApi = data => {
    return request({
        url: "/api/system/menu/update",
        method: "put",
        data
    });
};

export const deleteMenuApi = data => {
    return request({
        url: "/api/system/menu/delete",
        method: "delete",
        data
    });
};
