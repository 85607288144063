<template>
    <el-drawer
        title="绑定菜单"
        :visible.sync="isShow"
        direction="rtl"
        @open="openHandler"
        @closed="closedHandler"
    >
        <div class="drawer-content">
            <el-tree
                ref="menu-tree"
                class="menu-tree"
                node-key="id"
                :data="menus"
                :props="defaultProps"
                :default-checked-keys="defaultCheckMenus"
                default-expand-all
                show-checkbox
                check-on-click-node
                :expand-on-click-node="false"
                :key="key"
            >
            </el-tree>
            <div class="btn-ctrl">
                <el-button size="mini" @click="saveRelMenus" type="primary"
                    >保存</el-button
                >
                <el-button size="mini" @click="key++">重置</el-button>
                <el-button size="mini" @click="handlerClose">关闭</el-button>
            </div>
        </div>
    </el-drawer>
</template>
<script>
import {
    getMenusApi as getRoleMenusApi,
    bindMenusApi
} from "@/api/system/role";
import { getMenusApi as getAllMenusApi } from "@/api/system/menu";
import { genMenuChildren } from "@/utils/menu";

export default {
    name: "RoleMenu",
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        data: {
            type: Object,
            default: function() {
                return {};
            }
        }
    },
    computed: {
        isShow: {
            get() {
                return this.visible;
            },
            set(v) {
                this.$emit("update:visible", v);
            }
        },
        menus() {
            let menus = genMenuChildren(this.allMenus);
            return menus.filter(item => {
                return item.parent_id == -1 && item.type == 0;
            });
        },
        defaultCheckMenus() {
            let menus = genMenuChildren(this.roleMenus);
            return menus
                .filter(item => !item.children || item.children.length < 1)
                .map(item => item.id);
        },
        reqParams() {
            let id = this.data.id;
            let menus = [];

            let tree = this.$refs["menu-tree"];
            if (tree) {
                menus = [].concat(
                    tree.getHalfCheckedKeys(),
                    tree.getCheckedKeys()
                );
            }
            return {
                id,
                menus
            };
        }
    },
    data() {
        return {
            key: 0,
            allMenus: [],
            roleMenus: [],
            checkKeys: [],
            defaultProps: {
                label: "menu_name",
                children: "children"
            }
        };
    },
    methods: {
        async getAllMenu() {
            return new Promise((resolve, reject) => {
                getAllMenusApi({})
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$set(this, "allMenus", res.data.data);
                            resolve(res.data.data);
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        async getRoleMenu() {
            return new Promise((resolve, reject) => {
                getRoleMenusApi(this.data)
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$set(this, "roleMenus", res.data.data);
                            resolve(res.data.data);
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        async bindMenus() {
            return new Promise((resolve, reject) => {
                bindMenusApi(this.reqParams)
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$notify({
                                title: "成功",
                                message: "绑定成功",
                                type: "success"
                            });
                            this.handlerClose();
                            resolve(res.data.data);
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        async openHandler() {
            await this.getAllMenu();
            await this.getRoleMenu();
        },
        closedHandler() {
            this.$set(this, "allMenus", []);
            this.$set(this, "roleMenus", []);
        },
        handlerClose() {
            this.isShow = false;
        },
        saveRelMenus() {
            this.bindMenus();
        }
    }
};
</script>
<style lang="scss" scoped>
.drawer-content {
    padding: 20px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .menu-tree {
        flex: 1;
        overflow: auto;
    }
    .btn-ctrl {
        text-align: center;
        margin-top: 10px;
        > * + * {
            margin-left: 10px;
        }
    }
}
</style>
